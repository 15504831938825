@import "../../index.scss";

$primary: #211d3e;

.logo-monday {
    width: auto;
    height: 51px;
    object-fit: contain;

    @include media-breakpoint-down(sm) {
        width: 50%;
        height: 51px;
    }
}

.logo-control{
    width: 20%;
    display: flex;

    @include media-breakpoint-down(md){
        width: 70%;
    }
}

.container-xxl {
    width: 100% !important;
    max-width: 100% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    padding-top: 5px;
    padding-bottom: 5px;
}

.header-control {
    margin-top: 0px !important;
    padding-top: 0px !important;
}

.nav-title {
    color: $primary;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.nav-sub-title {
    color: $primary;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }
}

.btn-connect {
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    background-color: $primary !important;
    border-radius: 5rem !important;
    box-shadow: 0px 8px 16px 0px rgba(0, 38, 141, 0.102);
}

.link {
    font-size: 15px;
    font-weight: 500;
    margin-left: 16px;
    margin-right: 16px;
    width: auto;
    line-height: 24px;
    font-family: $fontRegular;
    color: $yellow !important;
    letter-spacing: 1.5px;

    &:hover{
        color: $red !important;
    }
}

.button-launch-app{
    cursor: pointer;
    height: 41px;
    border-style: none;
    padding-top: 6px;
    padding-bottom: 6px;
    width: auto;
    background:  $yellow !important;
    border: 3px solid white;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px;

    &:hover{
        border-color: white !important;
    }

    a{
        font-size: 15px;
        font-weight: 700;
        font-family: $fontRegular;
        color: black !important;
        text-decoration: none;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;


    }

    @include media-breakpoint-down(lg) {
        width: 70% !important;
        font-size: 19px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.navbar-md {
    @include media-breakpoint-down(sm) {
    }
}

.navbar-sm {
    @include media-breakpoint-down(sm) {
        display: block !important;
    }
}

.connect-wallet-sm {
    display: none;
    margin-top: 0.5rem;

    @include media-breakpoint-down(sm) {
        display: block;
    }
}
