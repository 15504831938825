@import "../../index.scss";


.container-control{
    background-color: $dark;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;


    .button-default {
        z-index: 1;
        cursor: pointer;
        margin-top: 30px;
        margin-bottom: 30px !important;
        min-width: 310px !important;
        height: 61px;
        font-size: 22px;
        border-style: none;
        font-weight: 700;
        font-family: $fontBold;
        color: white;
        padding-top: 6px;
        padding-bottom: 6px;
        width: 410px;
        background: black;
        border: 3px solid white;
        box-sizing: border-box;
        box-shadow: 4px 6px 0px white;
        border-radius: 8px;
        animation: bounce 2s infinite;
        @include media-breakpoint-down(md) {
            width: 100% !important;
            font-size: 19px;
            padding-top: 8px;
            padding-bottom: 8px;
        }

        @include media-breakpoint-down(sm) {
            width: 100% !important;
            min-height: auto !important;
            padding: 8px !important;
            height: auto;
        }
    }


    .vdo-section{
        height: auto;
        width: 100%;

        @include media-breakpoint-down(md){
            min-height: 0px;
            height: auto;
            padding-bottom: 0px !important;
            margin-bottom: 0px !important;
        }

        .vdo-file{
            margin-top: auto;
            width: 100%;
            height: auto;
            object-fit: cover;

         
        }
    }

    .section-1-control{
        position: relative;
        display: flex;
        padding-top: 60px;
        flex-direction: column;
        background: url(../../assets/images/bg-gain.jpg);
        background-repeat: no-repeat;
        background-position-y: center;
        background-size: cover;

        .img-pin{
            margin-top: 60px;
            width: 67px;
            height: 67px;
            object-fit: contain;
            animation: bounce 1.5s infinite;

            @include media-breakpoint-down(md){
                margin-top: 30px;
            }
        }

        .img-star{
            position: absolute;
            width: 70%;
            left: 0;
            z-index: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            height: auto;
        }

        .img-city{
            z-index: 1;
            width: 100%;
            height: 250px;
            background: url(../../assets/images/city-img.png);
            background-repeat: repeat-x;
            background-size: contain;
            margin-top: 80px !important;
            animation: runs 45s infinite;
            

            @include media-breakpoint-down(md){
                object-fit: cover;
                height: 100px;
            }
        }
        

        .text-monday{
            z-index: 1;
            font-size: 40px;
            margin-top: 16px;
            line-height: 1.6em;
            color: $dark;
            text-align: center;
            font-family: $fontBold;

            @include media-breakpoint-down(md){
                font-size: 30px;
            }
        }

        .text-desc{
            z-index: 1;
            font-size: 20px;
            margin-top: 16px;
            line-height: 1.6em;
            color: $dark;
            text-align: center;
            font-family: $fontRegular;

            @include media-breakpoint-down(md){
                font-size: 17px;
            }
        }
    }

    .section-2-control{
        position: relative;
        display: flex;
        padding-top: 60px;
        flex-direction: column;
        background: url(../../assets/images/bg-dark-star.jpg);
        background-repeat: no-repeat;
        background-position-y: top;
        background-size: cover;
        padding-bottom: 60px;

        .text-header{
            margin-top: 120px;
            font-size: 30px;
            color: white;
            line-height: 2.0em;
            font-family: $fontMedium;

            @include media-breakpoint-down(md){
                font-size: 22px;
                margin-top: 60px;
            }
        }

        .img-city-iso{
            cursor: pointer;
            width: 100%;
            margin-top: 100px;
            z-index: 1;
            height: auto;
            object-fit: contain;
            animation: bounce 5s infinite;
        }

        .img-star{
            position: absolute;
            top:0;
            bottom: 0;
            margin-top: auto;
            z-index: 0;
            width: 100%;
            height: auto;
            object-fit: contain;
        }

        .divinder-red{
            width: 100%;
            height: 2px;
            background-color: $red;
            margin-top: 120px;
        }

        .text-desc{
            margin-top: 120px;
            font-size: 24px;
            color: white;
            line-height: 2.0em;
            font-family: $fontMedium;
            margin-bottom: 120px;

            @include media-breakpoint-down(md){
                font-size: 20px;
                margin-top: 60px;
                margin-bottom: 60px;
            
            }
        }


    }
}