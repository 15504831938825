@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

$fontBold: "Poppins-Bold";
$fontRegular: "Poppins-Regular";
$fontMedium: "Poppins-Medium";
$fontLight: "Poppins-Light";
$fontItalic: "Poppins-Italic";

@font-face {
    font-family: $fontBold;
    src: local("Poppins-Bold"), url(../public/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
    font-family: $fontMedium;
    src: local("Poppins-Medium"), url(../public/fonts/Poppins-Medium.ttf) format("truetype");
}
@font-face {
    font-family: $fontRegular;
    src: local("Poppins-Regular"), url(../public/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
    font-family: $fontLight;
    src: local("Poppins-Light"), url(../public/fonts/Poppins-Light.ttf) format("truetype");
}
@font-face {
    font-family: $fontItalic;
    src: local("Poppins-Italic"), url(../public/fonts/Poppins-Italic.ttf) format("truetype");
}



$bgMain : #F9F5EB;
$yellow: #F7AF31;
$red : #EF4123;
$dark: #211D3E;
$blue : #A6EAFF;
$black: #212131;


@keyframes runs {
    0%{background-position: 0 0;}
    25%{background-position: 25vw 0;}
    50%{background-position: 50vw 0;}
    75%{background-position: 75vw 0;}
	100%{background-position: 100vw 0;}
  }

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -o-transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
        -webkit-transform: translateY(-10px);
        -o-transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -o-transform: translateY(0);
    }
}