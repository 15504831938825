@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "../../index.scss";

.container-control {
    background-color: black;
    padding-top: 40px;
    padding-bottom: 40px;
}

.grid-control {
    display: grid;
    grid-template-columns: 100%;

    .info-control {
        border-radius: 8px;
        background-color: white;
        padding: 24px;

        .text-header {
            font-size: 40px;
            color: black;
            font-weight: bold;
            font-family: $fontBold;
            text-align: center;
        }

        .cor-text {
            margin-top: 40px !important;
            font-size: 25px;
            color: black;
            font-weight: bold;
            font-family: $fontRegular;
        }

        .own-text {
            margin-top: 12px !important;
            font-size: 25px;
            color: black;
            font-weight: bold;
            font-family: $fontRegular;
        }

        .img-land {
            width: 120px;
            height: auto;
            object-fit: contain;
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.button-buyland {
    height: 61px;
    font-size: 22px;
    border-style: none;
    font-weight: 700;
    font-family: $fontRegular;
    color: var(--dark);
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 30px;
    width: 80%;
    background: var(--yellow);
    border: 3px solid var(--dark);
    box-sizing: border-box;
    box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px;

    @include media-breakpoint-down(md) {
        width: 100% !important;
        font-size: 19px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    @include media-breakpoint-down(sm) {
        min-width: auto !important;
        width: 100% !important;
        min-height: auto !important;
        padding: 8px !important;
        height: auto;
    }
}
.test-canvas {
    padding-left: 5%;
    padding-right: 5%;
    width: auto !important;
    height: auto;
}

.canvas-width{
    width: 100%;
}

.canvas-control {
    position: relative !important;
    z-index: 0;
    margin-left: auto;
    left: 0;
    margin-right: auto;
    right: 0;
}
.canvas-control-1 {
    position: absolute;
    z-index: 1;
    margin-left: auto;
    left: 0;
    margin-right: auto;
    right: 0;
}
.canvas-control-3 {
    z-index: 2;
    position: absolute;
    margin-left: auto;
    left: 0;
    margin-right: auto;
    right: 0;
}
.canvas-control-6 {
    z-index: 3;
    position: absolute;
    margin-left: auto;
    left: 0;
    margin-right: auto;
    right: 0;
}
.canvas-control-12 {
    z-index: 4;
    position: absolute;
    margin-left: auto;
    left: 0;
    margin-right: auto;
    right: 0;
}
.canvas-control-24 {
    z-index: 5;
    position: absolute;
    margin-left: auto;
    left: 0;
    margin-right: auto;
    right: 0;
}

.tileset-container {
    display: inline-block;
    width: 80% !important;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background-color: black;
    height: auto;
}

.tileset-selection-control{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.tileset-container_selection {
    position: absolute;
    outline: 3px solid red;
    left: 0;
    top: 0;
    width: 8px;
    height: 8px;
}

.map-company {
    object-fit: contain;
}
