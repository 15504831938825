@import "../../index.scss";


.footer-control {
    background-color: $dark;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    grid-template-columns: 50% 50%;

    @include media-breakpoint-down(md){
        grid-template-columns: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

.footer-control-black {
    background-color: black;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    grid-template-columns: 50% 50%;

    @include media-breakpoint-down(md){
        grid-template-columns: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

.footer-bottom-control{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;

    @include media-breakpoint-down(lg){
        grid-template-columns: 50% 50%;
    }

    @include media-breakpoint-down(md){
        grid-template-columns: 50% 50%;
    }
}

.footer-text-title{
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: bold;
    font-family: $fontBold;
    color: $yellow;
    line-height: 1.2em;
    text-decoration: none;
}

.footer-text-title:hover{
   color: $red !important;
}
.footer-text-li-about{
    margin-top: 32px;
    text-align: center;
    border-right-style: solid;
    border-right-color: $yellow;

    @include media-breakpoint-down(md){
        border-right-style: none;
        border-right-color: $yellow;
    }
}
.footer-text-li{
    margin-top: 32px;
    text-align: center;
    border-right-style: solid;
    border-right-color: $yellow;
}

.footer-text-li-last{
    margin-top: 32px;
    text-align: center;
    border-right-style: none;
    border-right-color: $yellow;
}

.logo {
    width: 300px;
    height: 100px;
    object-fit: contain;

    @include media-breakpoint-down(md){
        width: 300px;
        height: 100px;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }
}

.contact-control {
    justify-content: end !important;
    margin-left: auto !important;

    @include media-breakpoint-down(md){
        justify-content: center !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.follow-text {
    font-size: 40px;
    margin-left: auto;
    margin-bottom: 40px;
    font-weight: bold;
    font-family: $fontBold;
    color: $yellow;
    line-height: 1.2em;
    text-shadow: 2px 3px #000;

    @include media-breakpoint-down(md){
        font-size: 30px;
        margin-left: auto !important;
        margin-right: auto;
        text-align: center;
    }
}

.contact-item {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-left: 16px;
    margin-right: 16px;
    object-fit: contain;

    @include media-breakpoint-down(md){
        width: 30px;
        height: 30px;
    }

}
